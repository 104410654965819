<template>  
  <div class="rounded bg-primary p-lg-5 p-4">
    <div class="row align-items-end">
      <div class="col-md-8">
        <div class="section-title text-md-left text-center">
          <h4 class="title mb-3 text-white title-dark">{{props.title}}</h4>
          <p class="text-white-50 mb-0">{{props.description}}</p>
        </div>
      </div>
      <div class="col-md-4 mt-4 mt-sm-0">
        <div class="text-md-right text-center">
          <a href="javascript:void(0)" class="btn btn-light px-5">{{props.btnTxt}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      props: Object
    }
  };
</script>